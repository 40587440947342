import { Box, Typography } from '@mui/material';
import { labels } from '../../data/constants';
import ResearchTabComp from '../../Components/Research/ResearchTabComp';
import { useEffect, useState } from 'react';
import LanguageIcon from '@mui/icons-material/Language';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import BusinessIcon from '@mui/icons-material/Business';
import IdeaTabContent from '../../Components/Research/IdeaTabContent';
import MarketTabContent from '../../Components/Research/Market/MarketTabContent';
import CompetitorTabContent from '../../Components/Research/Competitor/CompetitorTabContent';
import { useSelector } from 'react-redux';
import { getBusinessIdeasService } from '../../Services/businessIdeasService';
import {
  createCompetitorService,
  fetchMarketsWithSubcategoriesService,
  getAssessmentListService,
  getCompetitorListService,
  getMarketListService,
  updateMarketService,
} from '../../Services/ResearchServices';
import { createMarketService } from '../../Services/ResearchServices';
import { useDispatch } from 'react-redux';

const ResearchComp = () => {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(0);
  const [ideaList, setIdeaList] = useState([]);
  const [assessmentList, setAssessmentList] = useState([]);
  const [marketsWithSubCategories, setMarketWithSubCategories] = useState([]);
  const [marketList, setMarketList] = useState([
    {
      id: 1,
      market_name: 'market 1',
      marketSubCategories: [
        {
          subCategoryName: 'Market Sub-Category 1',
          isNewSubCategory: true,
          keyMarket: '',
          keyBarrier: '',
          strengthWeakness: [
            {
              strengthType: 'scale',
              strengthName: 'Strength 1',
              strengthValue: 50,
            },
            {
              strengthType: 'switch',
              strengthName: 'Strength 2',
              strengthValue: true,
            },
            {
              strengthType: 'switch',
              strengthName: 'Strength 3',
              strengthValue: false,
            },
          ],
        },
        {
          subCategoryName: 'Market Sub-Category 2',
          isNewSubCategory: false,
          keyMarket: '',
          keyBarrier: '',
          strengthWeakness: [],
        },
        {
          subCategoryName: 'Market Sub-Category 3',
          isNewSubCategory: true,
          keyMarket: '',
          keyBarrier: '',
          strengthWeakness: [],
        },
      ],
    },
  ]);
  const [competitorList, setCompetitorList] = useState([
    {
      competitorName: 'Sample Competitor 1',
      competitorId: '1',
      relatedMarketSubCategories: [
        {
          marketSubCategoryId: '32',
          marketSubCategoryName: 'Category 1',
          strengthAndWeakness: [
            {
              strengthName: 'Brand',
              strengthType: 'scale',
              strengthOptions: [
                {
                  value: 0,
                  label: 'Unknown',
                },
                {
                  value: 25,
                  label: 'Weak',
                },
                {
                  value: 50,
                  label: 'Average',
                },
                {
                  value: 75,
                  label: 'Strong',
                },
                {
                  value: 100,
                  label: 'Market Leader',
                },
              ],
              strengthValue: 75,
            },
            {
              strengthName: 'Innovation Pace in Last 12 Months',
              strengthType: 'scale',
              strengthOptions: [
                {
                  value: 0,
                  label: 'Unknown',
                },
                {
                  value: 25,
                  label: 'Weak',
                },
                {
                  value: 50,
                  label: 'Average',
                },
                {
                  value: 75,
                  label: 'Strong',
                },
                {
                  value: 100,
                  label: 'Market Leader',
                },
              ],
              strengthValue: 100,
            },
            {
              strengthName: 'Course Offerings',
              strengthType: 'scale',
              strengthOptions: [
                {
                  value: 0,
                  label: 'Unknown',
                },
                {
                  value: 25,
                  label: 'Weak',
                },
                {
                  value: 50,
                  label: 'Average',
                },
                {
                  value: 75,
                  label: 'Strong',
                },
                {
                  value: 100,
                  label: 'Market Leader',
                },
              ],
              strengthValue: 50,
            },
            {
              strengthName: 'Integration with Existing Systems',
              strengthType: 'switch',
              strengthOptions: null,
              strengthValue: true,
            },
          ],
          competitiveAssessment: 'Healthy',
          comment: '',
        },
        {
          marketSubCategoryId: '34',
          marketSubCategoryName: 'Category 2',
          strengthAndWeakness: [
            {
              strengthName: 'Brand',
              strengthType: 'scale',
              strengthValue: 'average',
            },
          ],
          competitiveAssessment: 'Healthy',
          comment: '',
        },
      ],
      teamSize: '34',
      funding: '320000',
      annualRevenue: '100000',
      foundingYear: '2005',
    },
    {
      competitorName: 'Sample Competitor 2',
      competitorId: '2',
      relatedMarketSubCategories: [
        {
          marketSubCategoryId: '32',
          marketSubCategoryName: 'Category 1',
          strengthAndWeakness: [
            {
              strengthName: 'Brand',
              strengthType: 'scale',
              strengthValue: 'average',
            },
          ],
          competitiveAssessment: 'Healthy',
          comment: '',
        },
        {
          marketSubCategoryId: '34',
          marketSubCategoryName: 'Category 2',
          strengthAndWeakness: [
            {
              strengthName: 'Brand',
              strengthType: 'scale',
              strengthValue: 'average',
            },
          ],
          competitiveAssessment: 'Healthy',
          comment: '',
        },
      ],
      teamSize: '34',
      funding: '320000',
      annualRevenue: '100000',
      foundingYear: '2005',
    },
  ]);
  const [ideaOptions, setIdeaOptions] = useState([]);
  const [tabOptions, setTabOptions] = useState([
    {
      tabName: 'Ideas',
      tabIcon: <DesignServicesIcon />,
    },
    {
      tabName: 'Markets',
      tabIcon: <LanguageIcon />,
    },
    {
      tabName: 'Competitors',
      tabIcon: <BusinessIcon />,
    },
  ]);
  const handleTabChange = (event, tabInfo) => {
    setSelectedTab(tabInfo);
  };
  const userWorkspace = useSelector(
    (state) => state?.loginUserData?.user?.userDetails?.workspace
  );
  useEffect(() => {
    fetchIdeaList();
    fetchMarketList();
    fetchCompetitorsList();
    fetchMarketWithSubCategories();
    fetchAssessmentsList();
  }, [userWorkspace]);

  const fetchIdeaList = async () => {
    try {
      if (!userWorkspace?.id) {
        return;
      }
      const payload = {
        workspaceId: +userWorkspace.id,
      };
      const response = await getBusinessIdeasService(payload);
      if (response) {
        let ideaOptionList = [];
        response?.forEach((ideaOption) => {
          let idea = {
            label: ideaOption?.name,
            value: ideaOption?.id,
          };
          ideaOptionList.push(idea);
        });
        setIdeaOptions(ideaOptionList);
        setIdeaList(response);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const fetchAssessmentsList = async () => {
    try {
      if (!userWorkspace?.id) {
        return;
      }

      const response = await getAssessmentListService();
      if (response) {
        setAssessmentList(response);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const fetchCompetitorsList = async () => {
    try {
      if (!userWorkspace?.id) {
        return;
      }
      const payload = {
        workspaceId: +userWorkspace.id,
      };
      const response = await getCompetitorListService(payload);
      if (response) {
        let competitorList = [];

        response?.forEach((item) => {
          let competitor = {
            competitorName: item?.competitor_name,
            competitorId: item?.id,
          };
          competitorList.push(competitor);
        });
        setCompetitorList(competitorList);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchMarketList = async () => {
    try {
      if (!userWorkspace?.id) {
        return;
      }
      const payload = {
        workspaceId: +userWorkspace.id,
      };
      const response = await getMarketListService(payload);
      if (response) {
        setMarketList(response);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const AddMarket = async (marketName) => {
    try {
      const payload = {
        workspaceId: userWorkspace?.id,
        marketName: marketName,
      };
      const response = await createMarketService(payload, dispatch);
      if (response) {
        fetchMarketList();
        return true;
      }
    } catch (e) {
      console.log(e);
    }
  };
  const AddCompetitor = async (competitorName) => {
    try {
      const payload = {
        workspaceId: +userWorkspace?.id,
        competitorName: competitorName,
      };
      const response = await createCompetitorService(payload, dispatch);
      if (response) {
        fetchCompetitorsList();
        return true;
      }
    } catch (e) {
      console.log(e);
    }
  };
  const updateMarket = async (marketDetail) => {
    try {
      const payload = {
        workspaceId: userWorkspace?.id,
        marketName: marketDetail?.market_name,
        marketId: marketDetail?.id,
        cagr: marketDetail?.cagr || null,
        cagr_url: marketDetail?.cagr_url || null,
        tam: marketDetail?.tam || null,
        tam_url: marketDetail?.tam_url || null,
      };
      const response = await updateMarketService(payload, dispatch);
      if (response) {
        fetchMarketList();
        return true;
      }
    } catch (e) {
      console.log(e);
    }
  };
  const fetchMarketWithSubCategories = async () => {
    if (!userWorkspace?.id) {
      return;
    }
    let payload = {
      workspaceId: userWorkspace?.id,
    };
    const response = await fetchMarketsWithSubcategoriesService(payload);
    if (response) {
      setMarketWithSubCategories(response);
    }
  };
  return (
    <Box mt={2} ml={2} mr={2} pb={3}>
      <Typography ml={2} component={'h1'} variant="dashboardCompHeading">
        {labels.research.title}
      </Typography>
      <Box className="borderBottom2 borderColorGrey">
        <ResearchTabComp
          selectedTab={selectedTab}
          handleTabChange={handleTabChange}
          tabData={tabOptions}
        />
      </Box>
      <Box>
        {selectedTab === 0 && (
          <IdeaTabContent ideaOptionList={ideaOptions} ideaList={ideaList} />
        )}
        {selectedTab === 1 && (
          <MarketTabContent
            marketList={marketList?.sort((a, b) => a?.id - b?.id)}
            workspaceDetails={userWorkspace}
            handleAddMarket={AddMarket}
            fetchMarketList={fetchMarketList}
            updateMarket={updateMarket}
          />
        )}
        {selectedTab === 2 && (
          <CompetitorTabContent
            handleAddCompetitor={AddCompetitor}
            competitorList={competitorList}
            marketAndSubCategoryList={marketsWithSubCategories}
            assessmentList={assessmentList}
            fetchAssessmentsList={fetchAssessmentsList}
            fetchCompetitorsList={fetchCompetitorsList}
          />
        )}
      </Box>
    </Box>
  );
};

export default ResearchComp;
