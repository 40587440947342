import CustomDialog from '../../../Custom Components/CustomDialog/CustomDialog';
import {
  Box,
  Button,
  InputLabel,
  Typography,
  TextField,
  Switch,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';
import { labels } from '../../../../data/constants';
import { useState } from 'react';
const SubCategoryModal = ({
  handleCancel = () => {},
  submitDetails = () => {},
}) => {
  const [fieldValue, setFieldValue] = useState('');
  const [fieldError, setFieldError] = useState({ status: false, message: ' ' });
  const [isNewSubCategory, setIsNewSubCategory] = useState(false);
  const handleSubmit = () => {
    if (fieldValue === '') {
      setFieldError({
        status: true,
        message: 'Sub-Category name cannot be blank!',
      });
      return;
    }
    submitDetails(fieldValue, isNewSubCategory);
  };
  const handleSwitchChange = (event) => {
    setIsNewSubCategory(event.target.checked);
  };
  return (
    <CustomDialog
      handleOpen={true}
      showClose={false}
      width={'sm'}
      dialogTitle="Add Sub-Category"
      headingVariant="modalHeading1"
    >
      <Typography className="textAlignCenter" variant="paraText1">
        {labels.research.marketTab.marketCompetitorModal.userAutoReserachLabel}
      </Typography>
      <Typography className="textAlignCenter mt1 mb1" variant="paraText1">
        {labels.research.marketTab.marketCompetitorModal.orLabel}
      </Typography>
      <Typography variant="paraText1">
        If your business idea is highly innovative you may be creating an new
        sub-category, otherwise try to use an industry-standard name.
      </Typography>
      <Typography variant="paraText1">
        A sub-category is a more narrow portion of the market, focused on
        distinct customer use-cases, product offerings, or business strategies.
      </Typography>
      <Typography variant="paraText1">
        Here are some Market/Sub-Category examples:
      </Typography>
      <Typography variant="paraText1">
        <ul>
          <li>
            Footwear
            <ul>
              <li>Athletic Footwear</li>
              <li> Women's Luxury Footwear</li>
            </ul>
          </li>
          <li>
            Healthcare SaaS
            <ul>
              <li>Electronic Health Records SaaS</li>
              <li>Telemedicine SaaS</li>
            </ul>
          </li>
          <li>
            {' '}
            Business Consulting Services
            <ul>
              <li> Management Consulting</li>
              <li> Sales Leadership Consulting</li>
            </ul>
          </li>
        </ul>
        <Typography variant="paraText1">
          You should generally focus on just one sub-category to launch in, but
          you can create and work with more. This might be useful if you're
          trying to decide between several, or you will be launching in more
          than one (knowing the risks from reduced focus).
        </Typography>
      </Typography>
      <InputLabel
        shrink={false}
        required
        className="dashboardLabel1 displayFlex alignItemsCenter overflowVisible"
      >
        <Typography className="mt1">
          {labels.research.strengthWeaknessModal.nameLabel}
        </Typography>
      </InputLabel>
      <TextField
        className="textFieldType4 mt05 backgroundWhite"
        value={fieldValue}
        fullWidth
        placeholder={labels.research.strengthWeaknessModal.nameFieldPlaceholder}
        onChange={(e) => {
          setFieldError({ status: false, message: ' ' });
          setFieldValue(e.target.value);
        }}
      />
      <FormHelperText className="mt05" error={fieldError.status}>
        {fieldError.message}
      </FormHelperText>
      <FormControlLabel
        label="New Sub-Category in Market"
        control={
          <Switch
            checked={isNewSubCategory}
            onChange={handleSwitchChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        }
      />
      <Box className="justifySpaceBetween displayFlex mt1_5">
        <Button variant="buttonVariant1" onClick={handleCancel}>
          {labels.commonLabels.cancelLabel}
        </Button>
        <Button variant="buttonVariant1" onClick={handleSubmit}>
          {labels.commonLabels.submitLabel}
        </Button>
      </Box>
    </CustomDialog>
  );
};
export default SubCategoryModal;
