import { Typography, Box, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import CustomTabs from '../../Components/Custom Components/CustomTabs/CustomTab';
import ProfileTabContent from '../../Components/Profile/ProfileTabContent';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { NavLink, useNavigate } from 'react-router-dom';
import { labels, profileMsg } from '../../data/constants';
import CustomDialog from '../../Components/Custom Components/CustomDialog/CustomDialog';
import { useDispatch, useSelector } from 'react-redux';
import styles from './profile.module.css';
import {
  getUserTabsService,
  submitProfileDataService,
} from '../../Services/ProfileServices';
import ProfileSkeleton from '../../Components/Profile/ProfileSkeleton';
import { setUserDetails } from '../../redux/login';
import PdfUploader from '../../Components/CommonComponents/PDFUploader/PDFUploader';
const ProfileComp = () => {
  const dispatch = useDispatch();
  const userWorkspace = useSelector(
    (state) => state?.loginUserData?.user?.userDetails?.workspace
  );
  const userWorkspaces = useSelector(
    (state) => state?.loginUserData?.user?.userDetails?.allWorkspaces
  );
  const [showSkeleton, setShowSkeleton] = useState(true);
  const userProfile = useSelector(
    (state) => state?.loginUserData?.user?.userDetails
  );
  const navigate = useNavigate();
  const [profileTabStructure, setProfileTabStructure] = useState({
    tabs: [],
  });
  //Added a copy of tab data to compare answer change.
  const [profileTabStructureOriginal, setProfileTabStructureOriginal] =
    useState({
      tabs: [],
    });
  const [showCompleteProfileDialog, setShowCompleteProfileDailog] =
    useState(false);
  useEffect(() => {
    !userProfile?.role
      ? setShowCompleteProfileDailog(true)
      : setShowCompleteProfileDailog(false);
  }, [userProfile]);
  useEffect(() => {
    if (userWorkspace?.hideProfile) {
      navigate('/dashboard');
    }
    setProfileTabStructure({
      tabs: [],
    });
    setProfileTabStructureOriginal({
      tabs: [],
    });

    if (userProfile?.role && userWorkspace?.role !== 'guest') {
      setShowSkeleton(true);
      getUserTabData();
    }
  }, [userProfile, userWorkspace]);

  const getUserTabData = async () => {
    const payload = {
      workspaceid: userWorkspace.id,
    };
    const response = await getUserTabsService(payload);
    if (response && response?.length !== 0) {
      let tabData = [];
      response.forEach((tabItem) => {
        let answeredQuestion = 0;
        for (let i = 0; i < tabItem.questions.length; i++) {
          if (tabItem.questions[i].answer !== null) {
            if (tabItem.questions[i]?.type === 'multiSelect') {
              let answerStringInArray = tabItem.questions[i].answer.split(',');
              let answerArray = [];
              answerStringInArray.forEach((item) => {
                if (item !== ' ') {
                  let answerLabel;
                  tabItem?.questions[i]?.options?.forEach((option) => {
                    if (option?.value === item) {
                      answerLabel = option?.label;
                    }
                  });
                  answerArray.push({ label: answerLabel, value: item });
                }
              });
              tabItem.questions[i].answer = answerArray;
              if (answerArray.length !== 0) {
                answeredQuestion++;
              }
            } else if (tabItem.questions[i]?.type === 'select') {
              let answerObject;
              tabItem?.questions[i]?.options?.forEach((option) => {
                if (option?.value === tabItem.questions[i].answer) {
                  answerObject = {
                    label: option?.label,
                    value: tabItem.questions[i].answer,
                  };
                }
              });
              tabItem.questions[i].answer = answerObject;
              answeredQuestion++;
            } else {
              if (tabItem.questions[i].answer !== ' ') {
                answeredQuestion++;
              }
            }
          }
        }
        let tabDataItem = {
          ...tabItem,
          completionPercentage:
            (answeredQuestion / tabItem.questions.length) * 100,
        };
        tabData.push(tabDataItem);
      });
      setProfileTabStructure({ tabs: [...tabData] });
      //Duplicating tab data without reference.
      let stringifiedData = JSON.stringify(tabData);
      setProfileTabStructureOriginal({ tabs: JSON.parse(stringifiedData) });
      setShowSkeleton(false);
    }
  };
  const [selectedTab, setSelectedTab] = useState(0);
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const handleDataChange = (questionId, answer) => {
    if (answer !== undefined) {
      let tabDataCopy = { ...profileTabStructure };
      tabDataCopy.tabs.forEach((tab) => {
        tab.questions.forEach((questionOption) => {
          if (questionOption.id === questionId) {
            questionOption.answer = answer;
          }
        });
      });
      calculateCompletionPercentage(tabDataCopy);
    }
  };
  const calculateCompletionPercentage = (tabData) => {
    tabData.tabs.forEach((tab) => {
      let answeredQuestion = 0;
      let questionsLength = tab.questions.length;
      tab.questions.forEach((questionOption) => {
        if (questionOption.type === 'multiSelect') {
          if (
            Array.isArray(questionOption.answer) &&
            questionOption.answer?.length !== 0
          ) {
            answeredQuestion++;
          }
        } else {
          if (questionOption.answer && questionOption.answer !== ' ') {
            answeredQuestion++;
          }
        }
      });
      let percenteage = Math.round((answeredQuestion / questionsLength) * 100);
      tab.completionPercentage = percenteage;
    });
    setProfileTabStructure({ ...tabData });
  };
  const calculateProfileScore = (tabData) => {
    let overAllscore = 0;
    let tabsCompletionScore = 0;
    let tabsLength = tabData.tabs.length;
    tabData.tabs.forEach((tab) => {
      tabsCompletionScore += tab.completionPercentage;
    });
    overAllscore = Math.round(tabsCompletionScore / tabsLength);
    return overAllscore;
  };
  //This function will check if the answer is changed or not.
  const checkAnswerChange = (questionId, answer) => {
    let isAnswerChanged;
    //looping through each tab.
    profileTabStructureOriginal.tabs.forEach((tab) => {
      tab.questions.forEach((question) => {
        if (question.id === questionId) {
          //Checking if answer is array or not
          if (Array.isArray(answer)) {
            //Stringifying it to compare
            if (JSON.stringify(question.answer) === JSON.stringify(answer)) {
              isAnswerChanged = false;
              return;
            } else {
              isAnswerChanged = true;
              return;
            }
          } else {
            if (question.answer === answer) {
              isAnswerChanged = false;
              return;
            } else {
              isAnswerChanged = true;
              return;
            }
          }
        }
      });
    });
    return isAnswerChanged;
  };
  const submitProfileData = async (questionIdProvided, AnswerProvided) => {
    let isAnswerChanged = checkAnswerChange(questionIdProvided, AnswerProvided);
    if (isAnswerChanged !== true) {
      return;
    }
    let profileScore = calculateProfileScore(profileTabStructure);
    let answer = '';
    let answerList = [];
    if (Array.isArray(AnswerProvided)) {
      AnswerProvided.forEach((arrayItem) => {
        answerList.push(arrayItem.value);
      });
      answer = answerList.toString();
    } else {
      answer = AnswerProvided;
    }

    let payload = {
      questionId: questionIdProvided,
      answer: answer || ' ',
      score: profileScore,
      workspaceid: userWorkspace.id,
    };
    const response = await submitProfileDataService(payload, dispatch);
    if (response) {
      let userDtl = { ...userProfile };
      let workspaceListCopy = [...userWorkspaces];
      let userWorkspaceList = JSON.parse(JSON.stringify(workspaceListCopy));
      userWorkspaceList.forEach((workspaceItem, index) => {
        if (workspaceItem.id === userWorkspace?.id) {
          workspaceItem.profileScore = profileScore;
        }
      });
      userDtl.allWorkspaces = userWorkspaceList;
      window.localStorage.setItem('user', JSON.stringify(userDtl));
      dispatch(setUserDetails(userDtl));
      getUserTabData();
    }
  };

  return (
    <Box className="mt1 ml1 mr1 pb1_5">
      <Box className="displayFlex justifySpaceBetween">
        <Typography
          className="ml1"
          component={'h1'}
          variant="dashboardCompHeading"
        >
          {labels.profile.title}
        </Typography>

        <PdfUploader workspaceId={userWorkspace?.id} />
      </Box>
      <Box className="displayFlex alignItemsStart mt1 ml1 mr2 tipBackgroundWarning padding12x16 borderRadius4 tipColorWarning">
        <TipsAndUpdatesIcon fontSize="small" />
        <Box className="ml1">
          <Typography className="fontSize14 fontWeight700 mb1">
            {labels.profile.tipLabel1}
          </Typography>
          <Typography className="fontSize14 colorBlack">
            {profileMsg.fillSection}
          </Typography>
        </Box>
      </Box>
      {userProfile?.role ? (
        <Box ml={2} className={styles.customBox}>
          {
            <CustomTabs
              selectedTab={selectedTab}
              handleTabChange={handleChange}
              tabData={profileTabStructure.tabs}
            />
          }
        </Box>
      ) : (
        <CustomDialog handleOpen={showCompleteProfileDialog} showClose={false}>
          <Typography className="fontWeightBold textAlignCenter">
            {labels.profile.completeProfileDialogTitle}
          </Typography>
          <Typography className="textAlignCenter mt1_5">
            {labels.profile.completeProfileDialogMsg}
          </Typography>
          <Box className="textAlignCenter mt2">
            <Button
              variant="buttonVariant1"
              onClick={() => {
                navigate('/account');
              }}
            >
              {labels.profile.goToAccount}
            </Button>
          </Box>
        </CustomDialog>
      )}

      {selectedTab !== undefined && !showSkeleton && userProfile?.role && (
        <ProfileTabContent
          tabDataHandler={handleDataChange}
          submitAnswer={submitProfileData}
          tabContent={profileTabStructure.tabs[selectedTab]}
        />
      )}
      {showSkeleton && <ProfileSkeleton />}
      <Box className="displayFlex alignItemsStart mt1_5 ml1 mr2 tipBackgroundSuccess tipColorSuccess borderRadius4 padding12x16">
        <TipsAndUpdatesIcon fontSize="small" />
        <Box className="ml1">
          <Typography className="fontSize14 fontWeight700 mb1">
            {labels.profile.tipLabel1}
          </Typography>
          <Typography className="colorBlack fontSize14">
            {labels.profile.successTipMsg1}{' '}
            <NavLink to={'/businessIdeas'}>
              {labels.businessIdeas.title}
            </NavLink>
            {labels.profile.successTipMsg2}
          </Typography>
        </Box>
      </Box>
      {userWorkspace?.role === 'guest' && (
        <CustomDialog handleOpen={true} showClose={false}>
          <Typography textAlign={'center'} fontWeight={'bold'}>
            {'Access Denied'}
          </Typography>
          <Typography textAlign={'center'} mt={2}>
            {'Access to profile in guest workspace is denied'}
          </Typography>
          <Box textAlign={'center'} mt={3}>
            <Button
              variant="buttonVariant1"
              onClick={() => {
                navigate('/dashboard');
              }}
            >
              {'Go to Home'}
            </Button>
          </Box>
        </CustomDialog>
      )}
    </Box>
  );
};
export default ProfileComp;
