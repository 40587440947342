import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { labels } from '../../../data/constants';
import AddMarketOrCompetitorModal from '../AddMarketOrCompetitorModal';
import MarketInfoBlock from './MarketInfoBlock';
import {
  createMarketSubCategoryService,
  deleteMarketService,
} from '../../../Services/ResearchServices';
import { useDispatch } from 'react-redux';

const MarketTabContent = ({
  marketList = [],
  handleAddMarket = () => {},
  fetchMarketList = () => {},
  updateMarket = () => {},
}) => {
  const dispatch = useDispatch();
  const [showAddMarketModal, setShowAddMarketModal] = useState(false);
  const [activeMarket, setActiveMarket] = useState(null);
  const addMarket = async (marketName) => {
    const status = await handleAddMarket(marketName);
    if (status) {
      setShowAddMarketModal(false);
    }
  };

  const addMarketSubCategory = async (subCategoryName, isNewSubCategory) => {
    try {
      let payload = {
        marketId: activeMarket?.id,
        subCategoryName: subCategoryName,
        newSubCategory: isNewSubCategory,
      };
      const response = await createMarketSubCategoryService(payload, dispatch);
      if (response) {
        return true;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const deleteMarket = async (marketId, workspaceId) => {
    try {
      let payload = {
        workspaceId: workspaceId,
        marketId: marketId,
      };
      const response = await deleteMarketService(payload, dispatch);
      if (response) {
        setActiveMarket(null);
        fetchMarketList();
        return true;
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Box>
      <Typography
        component={'h3'}
        variant="dashboardCompHeading"
        className="fontSize26 mt1"
      >
        {labels.research.marketTab.heading}
      </Typography>
      {marketList?.length && marketList?.length !== 0 ? (
        <Grid container className="mt1">
          <Grid item lg={2}>
            <Stack
              className="backgroundLightGrey borderRadius8 padding12x16"
              spacing={1}
              divider={<Divider />}
            >
              {marketList?.map((marketOption, index) => (
                <Button
                  key={'marketOption' + index}
                  className=""
                  variant="stackButton"
                  fullWidth
                  onClick={() => {
                    setActiveMarket(marketOption);
                  }}
                >
                  {marketOption?.market_name}
                </Button>
              ))}
            </Stack>
            <Button
              className="mt1 textTrasformCapital"
              variant="buttonVariant1"
              onClick={() => {
                setShowAddMarketModal(true);
              }}
            >
              {labels.research.marketTab.addMarketBtnLabel}
            </Button>
          </Grid>
          <Grid item lg={10}>
            {activeMarket ? (
              <MarketInfoBlock
                updateMarketDetail={updateMarket}
                marketData={activeMarket}
                handleAddSubCategory={addMarketSubCategory}
                marketDeleteHandler={deleteMarket}
              />
            ) : (
              <Typography
                component={'h2'}
                fontSize={24}
                fontWeight={'bold'}
                className="ml2"
              >
                Please select a market.
              </Typography>
            )}
          </Grid>
        </Grid>
      ) : (
        <Box className="textAlignCenter">
          <Typography
            component={'h2'}
            mt={5}
            fontSize={24}
            fontWeight={'bold'}
            textAlign={'center'}
          >
            {labels.research.marketTab.noMarketMsg}
          </Typography>
          <Button
            className="mt1 textTrasformCapital"
            variant="buttonVariant1"
            onClick={() => {
              setShowAddMarketModal(true);
            }}
          >
            {labels.research.marketTab.addMarketBtnLabel}
          </Button>
        </Box>
      )}
      {showAddMarketModal && (
        <AddMarketOrCompetitorModal
          dialogTitle={'Add Market'}
          toAdd={'Market'}
          handleClose={() => {
            setShowAddMarketModal(false);
          }}
          submitMarketDetail={addMarket}
        />
      )}
    </Box>
  );
};
export default MarketTabContent;
