import {
  Box,
  Typography,
  IconButton,
  TextField,
  Button,
  FormHelperText,
  Switch,
  FormControlLabel,
  InputLabel,
} from '@mui/material';
import { useEffect, useState } from 'react';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { labels } from '../../../../data/constants';
import {
  createStrengthWeaknessService,
  deleteMarketSubCategoryService,
  deleteStrengthWeaknessService,
  updateMarketSubCategoryService,
} from '../../../../Services/ResearchServices';
import ResearchDeleteModal from '../../ResearchDeleteModal';
import StrengthWeaknessModal from '../../StrengthWeaknessModal';
import { useDispatch } from 'react-redux';

const SubCategoryPanel = ({
  marketSubCategoryOption = null,
  fetchSubCategories = () => {},
}) => {
  const dispatch = useDispatch();
  const [nameEdit, setNameEdit] = useState(false);
  const [nameError, setNameError] = useState({
    status: false,
    msg: '',
  });
  const [subCategoryDetail, setSubCategoryDetail] = useState(null);
  const [subCategoryDetailCopy, setSubCategoryDetailCopy] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSWDeleteModal, setShowSWDeleteModal] = useState(false);
  const [showSWModal, setShowSWModal] = useState(false);
  const [selectedStrength, setSelectedStrength] = useState(null);
  useEffect(() => {
    if (marketSubCategoryOption) {
      setSubCategoryDetail(JSON.parse(JSON.stringify(marketSubCategoryOption)));
      setSubCategoryDetailCopy(
        JSON.parse(JSON.stringify(marketSubCategoryOption))
      );
    }
  }, [marketSubCategoryOption]);
  const updateCategoryName = async () => {
    if (
      subCategoryDetail?.subCategoryName ===
      subCategoryDetailCopy?.subCategoryName
    ) {
      setNameError({
        status: true,
        msg: 'Name not changed!',
      });
      return;
    }
    let payload = {
      subCategoryId: subCategoryDetail?.id,
      marketId: subCategoryDetail?.marketId,
      subCategoryName: subCategoryDetail?.subCategoryName,
    };
    const response = await updateMarketSubCategoryService(payload, dispatch);
    if (response) {
      setNameEdit(false);
    }
  };

  const handleSwitchChange = (event) => {
    let value = event.target.checked;
    setSubCategoryDetail((prevState) => {
      return { ...prevState, isNewSubCategory: value };
    });
    updateCategoryDetails(
      subCategoryDetail?.id,
      subCategoryDetail?.marketId,
      value.toString(),
      'isNewSubCategory',
      'newSubCategory'
    );
  };

  const updateCategoryDetails = async (
    subCategoryId,
    marketId,
    value,
    objectField,
    payloadField
  ) => {
    try {
      if (value === subCategoryDetailCopy[objectField]) {
        return;
      }
      let payload = {
        subCategoryId: subCategoryId,
        marketId: marketId,
      };
      payload[payloadField] = value;
      const response = await updateMarketSubCategoryService(payload, dispatch);
      if (response) {
        let obj = { ...subCategoryDetailCopy };
        obj[objectField] = value;
        setSubCategoryDetailCopy(obj);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const deleteSubCategory = async () => {
    let payload = {
      subCategoryId: subCategoryDetail?.id,
    };
    const response = await deleteMarketSubCategoryService(payload, dispatch);
    if (response) {
      setShowDeleteModal(false);
      fetchSubCategories();
    }
  };

  const createStrengthWeakness = async (swName, swType) => {
    let payload = {
      subCategoryId: subCategoryDetail?.id,
      name: swName,
      type: swType,
    };
    const response = await createStrengthWeaknessService(payload, dispatch);
    if (response) {
      setShowSWModal(false);
      fetchSubCategories();
    }
  };
  const deleteStrengthWeakness = async () => {
    let payload = {
      strengthWeaknessId: +selectedStrength,
    };
    const response = await deleteStrengthWeaknessService(payload, dispatch);
    if (response) {
      setShowSWDeleteModal(false);
      setSelectedStrength(null);
      fetchSubCategories();
    }
  };
  return (
    <Box className="backgroundLightGrey borderRadius8 padding12x16 mt1 border1 borderColorGrey">
      {!nameEdit ? (
        <Box className="displayFlex alignItemsCenter">
          <Typography
            component={'h4'}
            variant="dashboardCompHeading"
            className="fontSize18 textTrasformCapital mr1"
          >
            {subCategoryDetail?.subCategoryName}
          </Typography>
          <IconButton
            aria-label="delete"
            onClick={() => {
              setNameEdit(true);
            }}
          >
            <CreateOutlinedIcon fontSize="small" />
          </IconButton>
          <IconButton
            onClick={() => {
              setShowDeleteModal(true);
            }}
            aria-label="delete"
            color="error"
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Box>
      ) : (
        <Box>
          <Box className="displayFlex alignItemsCenter">
            <TextField
              className="textFieldType4 mr1 mt0 mb0"
              value={subCategoryDetail?.subCategoryName || ''}
              onChange={(e) => {
                setSubCategoryDetail((prevState) => {
                  return { ...prevState, subCategoryName: e.target.value };
                });
              }}
            />
            <Button
              className="textAlignCenter textTransformNone"
              variant="purpleBtnTransparent"
              onClick={updateCategoryName}
            >
              {labels.research.updateBtnLabel}
            </Button>
            <Button
              className="textAlignCenter textTransformNone"
              variant="purpleBtnTransparent"
              onClick={() => {
                setSubCategoryDetail((prevState) => {
                  return {
                    ...prevState,
                    subCategoryName: subCategoryDetailCopy?.subCategoryName,
                  };
                });
                setNameEdit(false);
              }}
            >
              {labels.research.cancelBtnLabel}
            </Button>
          </Box>
          <FormHelperText className="mt05" error={nameError.status}>
            {nameError.msg}
          </FormHelperText>
        </Box>
      )}
      <FormControlLabel
        control={
          <Switch
            checked={subCategoryDetail?.isNewSubCategory || false}
            onChange={handleSwitchChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        }
        label={labels.research.marketTab.newSubCategoryLabel}
      />
      <InputLabel
        shrink={false}
        className="dashboardLabel1 displayFlex alignItemsCenter overflowVisible"
      >
        <Typography className="mt1">
          {labels.research.marketTab.subCategoryPanel.keyMarketChallenge.label}
        </Typography>
      </InputLabel>
      <TextField
        className="textFieldType4 mt05 backgroundWhite"
        value={subCategoryDetail?.keyMarket || ''}
        multiline
        fullWidth
        minRows={2}
        onChange={(event) => {
          setSubCategoryDetail((prevState) => {
            return {
              ...prevState,
              keyMarket: event.target.value,
            };
          });
        }}
        placeholder={
          labels.research.marketTab.subCategoryPanel.keyMarketChallenge
            .placeholder
        }
        onBlur={(event) => {
          updateCategoryDetails(
            subCategoryDetail?.id,
            subCategoryDetail?.marketId,
            event.target.value,
            'keyMarket',
            'keyMarketChallenge'
          );
        }}
      />
      <InputLabel
        shrink={false}
        className="dashboardLabel1 displayFlex alignItemsCenter overflowVisible"
      >
        <Typography className="mt1">
          {labels.research.marketTab.subCategoryPanel.keyBarrier.label}
        </Typography>
      </InputLabel>
      <TextField
        className="textFieldType4 mt05 backgroundWhite"
        value={subCategoryDetail?.keyBarrier || ''}
        multiline
        fullWidth
        minRows={2}
        placeholder={
          labels.research.marketTab.subCategoryPanel.keyBarrier.placeholder
        }
        onChange={(event) => {
          setSubCategoryDetail((prevState) => {
            return {
              ...prevState,
              keyBarrier: event.target.value,
            };
          });
        }}
        onBlur={(event) => {
          updateCategoryDetails(
            subCategoryDetail?.id,
            subCategoryDetail?.marketId,
            event.target.value,
            'keyBarrier',
            'keyBarriers'
          );
        }}
      />
      <InputLabel
        shrink={false}
        className="dashboardLabel1 overflowVisible displayFlex alignItemsCenter"
      >
        {subCategoryDetail?.strengthWeakness?.length !== 0 && (
          <Typography>{labels.research.strengthWeaknessLabel}</Typography>
        )}
      </InputLabel>
      {subCategoryDetail?.strengthWeakness?.length !== 0 &&
        subCategoryDetail?.strengthWeakness?.map((strengthOption, index) => (
          <Box
            key={'strengthOption' + index}
            className="displayFlex alignItemsCenter justifySpaceBetween mt1 backgroundWhite border1 borderColorGrey borderRadius8 padding05"
          >
            <InputLabel
              shrink={false}
              className="dashboardLabel1 overflowVisible displayFlex alignItemsCenter textTrasformCapital"
            >
              <Typography>{strengthOption?.name}</Typography>
            </InputLabel>
            <IconButton
              onClick={() => {
                setSelectedStrength(strengthOption?.id);
                setShowSWDeleteModal(true);
              }}
              aria-label="delete"
              color="error"
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        ))}
      <Button
        className="textTrasformCapital mt1"
        onClick={() => {
          setShowSWModal(true);
        }}
        variant="buttonVariant1"
      >
        {labels.research.strengthWeaknessBtnLabel}
      </Button>
      {showSWModal && (
        <StrengthWeaknessModal
          handleCancel={() => {
            setShowSWModal(false);
          }}
          submitDetails={createStrengthWeakness}
        />
      )}
      {showDeleteModal && (
        <ResearchDeleteModal
          title={labels.research.marketTab.marketSubCategoryDeleteModal.heading}
          message1={
            labels.research.marketTab.marketSubCategoryDeleteModal.message1
          }
          message2={
            labels.research.marketTab.marketSubCategoryDeleteModal.message2
          }
          handleClose={() => {
            setShowDeleteModal(false);
          }}
          confirmDeleteMarket={deleteSubCategory}
        />
      )}
      {showSWDeleteModal && (
        <ResearchDeleteModal
          title={labels.research.deleteStrengthWeaknessModal.title}
          message1={labels.research.deleteStrengthWeaknessModal.message1}
          handleClose={() => {
            setShowSWDeleteModal(false);
          }}
          confirmDeleteMarket={deleteStrengthWeakness}
        />
      )}
    </Box>
  );
};

export default SubCategoryPanel;
