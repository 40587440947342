import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { labels } from "../../data/constants";
import { useState } from "react";
import { sendInvite } from "../../Services/WorkspaceService";
import { useDispatch } from 'react-redux'

const InviteCollaborators = ({ userWorkspace, getListCollab, isGuest }) => {
  const greyBox = {
    backgroundColor: "rgb(243, 243, 243)",
    boxShadow: "none",
    border: "1px solid rgb(243, 243, 243)",
    borderRadius: "8px",
    padding: "12px",
  };
  const dispatch = useDispatch();
  const [selectedPermission, setSelectedPermission] = useState("commenter");
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [permissionError, setPermissionError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isProfileHide, setIsProfileHide] = useState(false);

  const handlePermission = (event) => {
    setSelectedPermission(event.target.value);
    if (permissionError) {
      setPermissionError(false);
    }
  };
  const handleProfileHideChange = (event) => {
    console.log(event.target.checked);

    setIsProfileHide(event.target.checked);
  };
  const handleInvite = async () => {
    if (!selectedPermission) {
      setPermissionError(true);
    } else {
      setPermissionError(false);
    }
    if (!email) {
      setError(true);
      setErrorMessage("Invalid email");
    } else {
      setError(false);
      setErrorMessage("");
    }
    if (selectedPermission && email) {
      const payload = {
        inviteeEmail: email,
        role: selectedPermission,
        hideProfile: isProfileHide,
        workspaceId: userWorkspace?.id,
      };
      await sendInvite(payload, dispatch);
      setEmail("");
      setSelectedPermission("commenter");
      setIsProfileHide(false);
      await getListCollab();
    }
  };
  return (
    <Grid item xs={12}>
      <Box sx={greyBox}>
        {isGuest ? (
          <>
            <Typography>
              {labels.workspace.guest.inviteCollabMessage1}
            </Typography>
            <Typography>
              {labels.workspace.guest.inviteCollabMessage2}
            </Typography>
          </>
        ) : (
          <>
            <Typography component={"h3"} variant="dashboardCompHeading2">
              {labels.workspace.inviteLabel}
            </Typography>
            <Box className="displayFlex">
              <TextField
                fullWidth
                sx={{ width: "78%" }}
                className="textFieldType3"
                placeholder="user@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={error}
                helperText={error ? errorMessage : ""}
                FormHelperTextProps={{
                  sx: {
                    marginLeft: "0px",
                  },
                }}
              />
              <Button
                variant="buttonVariant1"
                className="textTransformNone ml1"
                onClick={handleInvite}
              >
                {labels.workspace.inviteButtonLabel}
              </Button>
              <Typography variant="customStyle2" sx={{ height: "fit-content" }}>
                {labels.pro.title}
              </Typography>
            </Box>
            <Box
              sx={{ minHeight: "30px", display: "flex", alignItems: "center" }}
            >
              {selectedPermission === "commenter" && (
                <Typography className="fontSize12" sx={{minHeight:'18px',marginTop:'6px'}}>
                  {labels.workspace.commenterText}
                </Typography>
              )}
              {selectedPermission === "editor" && (
                <Typography className="fontSize12" sx={{minHeight:'18px',marginTop:'6px'}}>
                  {labels.workspace.editorText}
                </Typography>
              )}
            </Box>
            <Grid container>
              <Grid item xs={2}>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                    value={selectedPermission}
                    onChange={handlePermission}
                    className="fontSize12"
                  >
                    <FormControlLabel
                      value="commenter"
                      control={<Radio />}
                      label="Commenter"
                    />
                    <FormControlLabel
                      value="editor"
                      className="fontSize12"
                      control={<Radio />}
                      label="Editor"
                    />
                  </RadioGroup>
                  {permissionError && (
                    <FormHelperText sx={{ color: "#d32f2f" }}>
                      Select a role
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={<Checkbox checked={isProfileHide} />}
                  onChange={handleProfileHideChange}
                  label="Hide my profile"
                />
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </Grid>
  );
};
export default InviteCollaborators;
