const apiUrl = process.env.REACT_APP_API_URL;
const baseUrlApi = apiUrl;
const BASE_IMAGE_URL = process.env.REACT_APP_BASE_IMAGE_URL;
const TERMS_AND_CONDITIONS_URL = process.env.REACT_APP_TERMS_AND_CONDITIONS_URL;
const ABOUT_US = process.env.REACT_APP_ABOUT_US;

let ApiConfig = {
  token: null,
  TERMS_AND_CONDITIONS_URL,
  ABOUT_US,
  BASE_IMAGE_URL,

  // AUTH
  login: `${baseUrlApi}/user/login`,
  signup: `${baseUrlApi}/user/register`,
  otpVerify: `${baseUrlApi}/user/verify`,
  resendOtp: `${baseUrlApi}/user/resendOtp`,
  //Dashboard
  logout: `${baseUrlApi}/user/logout`,
  dashboardDetails: `${baseUrlApi}/dashboard/dashboardMenu`,
  //Account
  rolesList: `${baseUrlApi}/user/rolesList`,
  updateEmail: `${baseUrlApi}/user/updateEmail`,
  userDetails: `${baseUrlApi}/user/userDetails`,
  updateRole: `${baseUrlApi}/user/updaterole`,
  updateUserDetails: `${baseUrlApi}/user/updateUserDetails`,
  locationList: `${baseUrlApi}/user/locationList`,
  userTabs: `${baseUrlApi}/profile/userTabs`,
  questionAnswers: `${baseUrlApi}/profile/questionsAnswers`,
  verifyEmailOtp: `${baseUrlApi}/user/verifyEmail`,
  //Business Ideas
  generateBusinessIdeas: `${baseUrlApi}/business/generateBusinessIdea`,
  generateManualBusinessIdeas: `${baseUrlApi}/business/generateManualBusinessIdea`,
  listBusinessIdeas: `${baseUrlApi}/business/listBusinessIdea`,
  //Idea details
  ideaDetails: `${baseUrlApi}/business/ideadetailTabs`,
  saveIdeaDetails: `${baseUrlApi}/business/editIdea`,
  saveIdeaQuestionAnswers: `${baseUrlApi}/business/ideaDetailsQuestionsAnswers`,
  ideaAiAutofill: `${baseUrlApi}/business/ideaaiautofill`,
  likeDislike: `${baseUrlApi}/business/likedislike`,
  ideaLock: `${baseUrlApi}/business/idealocked`,
  ideaVariant: `${baseUrlApi}/business/ideavariant`,
  ideaShare: `${baseUrlApi}/business/shareidea`,
  sharedIdeaDetails: `${baseUrlApi}/business/ideaDetail`,
  rateIdea: `${baseUrlApi}/filters/rateidea`,
  categorizedIdeas: `${baseUrlApi}/filters/categorizeideas`,
  addTopIdeas: `${baseUrlApi}/filters/addtopideas`,
  listIdeaGrades: `${baseUrlApi}/business/listideagrade`,
  fetchResume: `${baseUrlApi}/business/resume`,
  fetchBusinessPlan: `${baseUrlApi}/businessPlan/businessPlanReport`,
  //workspace
  editWorkspaceName: `${baseUrlApi}/workspace/editWorkspaceName`,
  deleteWorkspace: `${baseUrlApi}/workspace/deleteWorkspace`,
  createWorkspace: `${baseUrlApi}/workspace/createWorkspace`,
  listWorkspace: `${baseUrlApi}/workspace/listworkspace`,
  invite: `${baseUrlApi}/workspace/inviteWorkspaceCollaborator`,
  listCollaborators: `${baseUrlApi}/workspace/listcollaborators`,
  editCollaborator: `${baseUrlApi}/workspace/editcollaborators`,
  deleteCollaborator: `${baseUrlApi}/workspace/deletecollaborators`,
  reinviteCollaborator: `${baseUrlApi}/workspace/reInviteWorkspaceCollaborator`,
  acceptCollaborator: `${baseUrlApi}/workspace/acceptWorkspaceCollaborationInvitation`,
  guestWorkspacePriveleges: `${baseUrlApi}/workspace/workspaceguestdetail`,
  userWorkspaceDetail: `${baseUrlApi}/workspace/workspacedetail`,
  //Business Plan
  ideaAndTabDetails: `${baseUrlApi}/businessPlan/businessPlanTabs`,
  bnp_questionAnswers: `${baseUrlApi}/businessPlan/businessPlanQuestionsAnswers`,
  // career pathways
  getCareerPathways: `${baseUrlApi}/careerPath/getCareerPath`,
  generateCareerPathways: `${baseUrlApi}/careerPath/generateCareerPath`,
  uploadResume: `${baseUrlApi}/careerPath/uploadResume`,
  //Research
  createMarket: `${baseUrlApi}/research/createMarket`,
  listMarket: `${baseUrlApi}/research/listMarkets`,
  updateMarket: `${baseUrlApi}/research/updateMarket`,
  deleteMarket: `${baseUrlApi}/research/deleteMarket`,
  createMarketSubCategory: `${baseUrlApi}/research/createSubCategory`,
  listMarketSubCategories: `${baseUrlApi}/research/listMarketSubCategories`,
  updateMarketSubCategory: `${baseUrlApi}/research/updateSubCategory`,
  deleteMarketSubCategory: `${baseUrlApi}/research/deleteSubCategory`,
  createStrengthWeakness: `${baseUrlApi}/research/createStrengthWeakness`,
  updateStrengthWeakness: `${baseUrlApi}/research/updateStrengthWeakness`,
  deleteStrengthWeakness: `${baseUrlApi}/research/deleteStrengthWeakness`,
  marketsWithSubCategories: `${baseUrlApi}/competitor/listMarketsWithSubCategories`,
  createCompetitor: `${baseUrlApi}/competitor/createCompetitor`,
  listCompetitors: `${baseUrlApi}/competitor/listCompetitors`,
  listCompetitorDetails: `${baseUrlApi}/competitor/listCompetitorDetails`,
  updateCompetitor: `${baseUrlApi}/competitor/updateCompetitor`,
  linkCompetitorToMarket: `${baseUrlApi}/competitor/addSubcategoryToCompetitor`,
  saveCompSubCatDetails: `${baseUrlApi}/research/saveSubcategoryCompetitorDetails`,
  listAssessment: `${baseUrlApi}/competitor/listcompetitiveAssessment`,
  addAssessment: `${baseUrlApi}/competitor/createcompetitiveassesment`,
  delteSubCatProfile: `${baseUrlApi}/competitor/deleteSubcategoryFromCompetitor`,
  deleteCompetitor: `${baseUrlApi}/competitor/deleteCompetitor`,
};

export default ApiConfig;
