import axios from 'axios';
import ApiConfig from '../config/ApiConfig';
import { toast } from 'react-toastify';
import {
  STATUS_200,
  STATUS_300,
  STATUS_401,
  STATUS_403,
} from '../data/constants';
import { errorMessage } from '../lib/helper';
import { setLoading } from '../redux/dashboardMenu';
import { saving, saved, couldnotSave } from '../redux/saveSlice';
import { setUserDetails } from '../redux/login';

const showErrorMessage = (e) => {
  if (e?.data?.status !== STATUS_401 && e?.data?.status !== STATUS_403) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
  }
};
export const GetRolesList = () => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const resp = await axios.get(`${ApiConfig.rolesList}`);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        dispatch(setLoading(false));
        return resp?.data?.data;
      } else {
        throw resp;
      }
    } catch (e) {
      showErrorMessage(e);
      dispatch(setLoading(false));
      return false;
    }
  };
};

export const UpdateRole = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setLoading(true));
        dispatch(saving());
        const response = await axios.post(`${ApiConfig.updateRole}`, payload);
        if (
          response?.data?.status >= STATUS_200 &&
          response?.data?.status < STATUS_300
        ) {
          // toast.success(response?.data?.message + response?.data?.data);
          dispatch(setLoading(false));
          dispatch(saved());
          return true;
        } else {
          // toast.error(response?.data?.message);
          dispatch(couldnotSave());
          throw response;
        }
      } catch (e) {
        showErrorMessage(e);
        dispatch(setLoading(false));
        dispatch(couldnotSave());
        return false;
      }
    };
  }
};
export const UpdateEmail = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setLoading(true));
        const response = await axios.post(`${ApiConfig.updateEmail}`, payload);
        if (
          response?.data?.status >= STATUS_200 &&
          response?.data?.status < STATUS_300
        ) {
          toast.success(response?.data?.message + response?.data?.data);
          dispatch(setLoading(false));
          return true;
        } else {
          toast.error(response?.data?.message);
          throw response;
        }
      } catch (e) {
        showErrorMessage(e);
        dispatch(setLoading(false));
        return false;
      }
    };
  }
};

export const getUserDetailService = async (dispatch) => {
  try {
    const resp = await axios.get(`${ApiConfig.userDetails}`);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      // dispatch(setUserDetails(resp?.data?.data));
      return resp?.data?.data;
    } else {
      throw resp;
    }
  } catch (e) {
    showErrorMessage(e);
    return false;
  }
};

export const updateUserDetailsService = async (payload) => {
  try {
    const response = await axios.post(
      `${ApiConfig.updateUserDetails}`,
      payload
    );
    if (
      response?.data?.status >= STATUS_200 &&
      response?.data?.status < STATUS_300
    ) {
      toast.success(response?.data?.message + response?.data?.data);
      return true;
    } else {
      toast.error(response?.data?.message);
      throw response;
    }
  } catch (e) {
    showErrorMessage(e);
    return false;
  }
};

export const getLocationListService = async () => {
  try {
    const resp = await axios.get(`${ApiConfig.locationList}`);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      return resp?.data?.data;
    } else {
      throw resp;
    }
  } catch (e) {
    showErrorMessage(e);
    return false;
  }
};

export const verifyEmailChngOtpService = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.verifyEmailOtp}`, payload);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      return true;
    } else {
      throw resp;
    }
  } catch (e) {
    showErrorMessage(e);
    return false;
  }
};
