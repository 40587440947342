import { configureStore } from '@reduxjs/toolkit';
import loginReducer from './login';
import dashboardMenuReducer from './dashboardMenu';
import saveReducer from './saveSlice';

export const store = configureStore({
  reducer: {
    loginUserData: loginReducer,
    dashboardMenuData: dashboardMenuReducer,
    saveStatus: saveReducer,
  },
});
