import { Box, Typography } from '@mui/material';
import { labels } from '../../data/constants';
import IdeaSelectorComp from '../BusinessPlan/ideaSelectorComp';

const IdeaTabContent = ({
  ideaOptionList,
  ideaList,
  handleIdeaChange = () => {},
}) => {
  return (
    <Box>
      <Typography className="mt1 mb1">
        {labels.research.ideaTab.subHeading}
      </Typography>
      <IdeaSelectorComp
        ideaOptions={ideaOptionList}
        ideaList={ideaList}
        handleIdeaChange={handleIdeaChange}
      />
    </Box>
  );
};
export default IdeaTabContent;
