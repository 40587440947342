import {
  Box,
  Typography,
  InputLabel,
  Slider,
  FormControlLabel,
  Switch,
  Button,
  IconButton,
} from '@mui/material';
import { labels, strengthLevels2 } from '../../data/constants';
import { useEffect, useState } from 'react';
import StrengthWeaknessModal from './StrengthWeaknessModal';
import DeleteIcon from '@mui/icons-material/Delete';
import ResearchDeleteModal from './ResearchDeleteModal';
import { strengthLevels } from '../../data/constants';

const StrengthAndWeaknessPanel = ({
  SWOptionsArray = [],
  tab = '',
  handleAddStrength = () => {},
  handleStrengthDelete = () => {},
  handleStrengthUpdate = () => {},
}) => {
  const [showModal, setShowModal] = useState(false);
  const [SWOptions, setSWOptions] = useState(SWOptionsArray);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedStrength, setSelectedStrength] = useState(null);
  const valueLabelFormat = (value) => {
    switch (value) {
      case 0:
        return strengthLevels[0].label;
      case 25:
        return strengthLevels[1].label;
      case 50:
        return strengthLevels[2].label;
      case 75:
        return strengthLevels[3].label;
      case 100:
        return strengthLevels[4].label;
      default:
        return strengthLevels[2].label;
    }
  };
  useEffect(() => {
    if (SWOptionsArray) {
      let optionArrayCopy = SWOptionsArray;
      optionArrayCopy?.forEach((option) => {
        if (option?.type === 'Scale') {
          if (option?.id === 2 || option?.id === 3) {
            switch (option?.level) {
              case 'Unknown':
                option.level = 0;
                break;
              case 'Weak':
                option.level = 25;
                break;
              case 'Average':
                option.level = 50;
                break;
              case 'Strong':
                option.level = 75;
                break;
              case 'Market Leader':
                option.level = 100;
                break;
              default:
                break;
            }
          } else {
            switch (option?.level) {
              case 'Very Weak':
                option.level = 0;
                break;
              case 'Weak':
                option.level = 25;
                break;
              case 'Average':
                option.level = 50;
                break;
              case 'Strong':
                option.level = 75;
                break;
              case 'Very Strong':
                option.level = 100;
                break;
              default:
                break;
            }
          }
        }
      });
      setSWOptions(() => {
        return [...optionArrayCopy];
      });
    }
  }, [SWOptionsArray]);
  const delteStrength = async () => {
    const response = await handleStrengthDelete(selectedStrength);
    if (response) {
      setShowDeleteModal(false);
    }
  };
  const updateStrengthLevel = (receivedValue, strengthId, field) => {
    let dataCopy = SWOptions;
    dataCopy.forEach((option) => {
      if (option?.id === strengthId) {
        option.level = receivedValue;
      }
    });
    setSWOptions(() => {
      return [...dataCopy];
    });
    if (field === 'switch') {
      handleStrengthUpdate(receivedValue, strengthId);
    } else {
      updateStrength(receivedValue, strengthId);
    }
  };

  const addStrength = async (fieldValue, typeValue) => {
    const response = await handleAddStrength(fieldValue, typeValue);
    if (response) {
      setShowModal(false);
    }
  };
  const updateStrength = (receivedValue, swId) => {
    switch (receivedValue) {
      case 0:
        handleStrengthUpdate(strengthLevels[0].label, swId);
        break;
      case 25:
        handleStrengthUpdate(strengthLevels[1].label, swId);
        break;
      case 50:
        handleStrengthUpdate(strengthLevels[2].label, swId);
        break;
      case 75:
        handleStrengthUpdate(strengthLevels[3].label, swId);
        break;
      case 100:
        handleStrengthUpdate(strengthLevels[4].label, swId);
        break;
      default:
        break;
    }
  };
  const handleSwitchChange = (event, sw_optionId) => {
    let value = event.target.checked;
    updateStrengthLevel(value, sw_optionId, 'switch');
  };
  return (
    <Box className="backgroundWhite borderRadius8 padding12x16">
      <Box className="swbox">
        {SWOptions?.sort((a, b) => a.id - b.id).map((sw_option, index) =>
          sw_option?.type === 'Scale' ? (
            <Box>
              <InputLabel
                sx={{
                  overflow: 'visible',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
                shrink={false}
                className="dashboardLabel1"
              >
                <Typography>{sw_option?.name}</Typography>
                {!sw_option?.is_default ? (
                  <IconButton
                    onClick={() => {
                      setSelectedStrength(sw_option?.id);
                      setShowDeleteModal(true);
                    }}
                    aria-label="delete"
                    color="error"
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                ) : (
                  ''
                )}
              </InputLabel>
              <Slider
                className="customSlider"
                step={25}
                value={sw_option?.level}
                valueLabelDisplay="auto"
                getAriaValueText={valueLabelFormat}
                valueLabelFormat={valueLabelFormat}
                onChange={(e) => {
                  updateStrengthLevel(e.target.value, sw_option?.id);
                }}
                min={0}
                max={100}
                aria-label={'sliderOption' + sw_option?.id}
                aria-labelledby={'sliderOption' + sw_option?.id}
              />
            </Box>
          ) : (
            <Box key={'swOption' + index}>
              <FormControlLabel
                control={
                  <Switch
                    onChange={(e) => {
                      handleSwitchChange(e, sw_option?.id);
                    }}
                    checked={JSON.parse(sw_option?.level)}
                  />
                }
                label={sw_option?.name}
              />
              <IconButton
                onClick={() => {
                  setSelectedStrength(sw_option?.id);
                  setShowDeleteModal(true);
                }}
                aria-label="delete"
                color="error"
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Box>
          )
        )}
      </Box>
      <Button
        className="textTrasformCapital mt1"
        variant="buttonVariant1"
        onClick={() => {
          setShowModal(true);
        }}
      >
        {labels.research.strengthWeaknessBtnLabel}
      </Button>
      {showModal && (
        <StrengthWeaknessModal
          submitDetails={addStrength}
          handleCancel={() => {
            setShowModal(false);
          }}
        />
      )}
      {showDeleteModal && (
        <ResearchDeleteModal
          title={'Delete Sub-Category Strength/Weakness'}
          message1={
            'Confirm you want to delete this Strength/Weakness? It will be deleted from all competitors in this sub-category.'
          }
          confirmDeleteMarket={delteStrength}
          handleClose={() => {
            setShowDeleteModal(false);
          }}
        />
      )}
    </Box>
  );
};
export default StrengthAndWeaknessPanel;
