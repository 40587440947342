import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { labels } from '../../data/constants';
import CreateIcon from '@mui/icons-material/Create';
import { Info } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
export default function TypingEffectTextField({
  handleBlur = () => {},
  handleChange = () => {},
  aiAutoFill = () => {},
  setQuestionLoader,
  tabItem = null,
  questionLoader,
  isDisabled,
  ideaId,
}) {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState(null);
  const [isTyping, setIsTyping] = useState(false);
  const [generatedBefore, setGeneratedBefore] = useState(false);
  const [storedAnswer, setStoredAnswer] = useState(null);
  const currentAnswerRef = useRef('');
  const handleInputChange = (event, tabItem) => {
    if (!isTyping) {
      if (
        tabItem?.question.includes('CAC') ||
        tabItem?.question.includes('ACV')
      ) {
        if (/^\d*$/.test(event.target.value)) {
          setInputValue(event.target.value);
        }
      } else {
        setInputValue(event.target.value);
        handleChange(tabItem?.id, event.target.value);
      }
    }
  };

  const handleAiCall = async (
    questionData,
    questionId,
    question,
    questionAnswer
  ) => {
    if (questionAnswer?.trim()) {
      return;
    }
    if (questionData?.isDependencyFullfilled === false) {
      return;
    }
    if (questionData?.allowAIAutofill === false) {
      return;
    }
    setIsTyping(true);
    setGeneratedBefore(true);
    setQuestionLoader({
      loading: true,
      forQuestion: questionId,
    });
    const aiResponse = await aiAutoFill(questionId, question);
    if (aiResponse) {
      setStoredAnswer(aiResponse);
      currentAnswerRef.current = '';
      typeOutAnswer(aiResponse);
      setQuestionLoader({
        loading: false,
        forQuestion: null,
      });
    }
  };

  const typeOutAnswer = (answer) => {
    let index = 0;
    const interval = setInterval(() => {
      if (index < answer.length) {
        currentAnswerRef.current += answer[index];
        setInputValue(currentAnswerRef.current);
        index++;
      } else {
        clearInterval(interval);
        setIsTyping(false);
      }
    }, 20);
  };

  useEffect(() => {
    if (generatedBefore && storedAnswer && !isTyping) {
      setInputValue(storedAnswer);
    }
    return () => {
      setInputValue('');
    };
  }, [generatedBefore, storedAnswer, isTyping]);
  useEffect(() => {
    if (tabItem) {
      if (tabItem?.answer) {
        setInputValue(tabItem?.answer.trim());
      } else {
        setInputValue('');
      }
    }
  }, [tabItem]);

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 500,
      backgroundColor: 'rgb(255,255,255)',
      color: 'rgb(0, 0, 0)',
      border: '4px solid rgb(242, 178, 15)',
    },
  });

  const CustomToolTipContent = ({ title }) => {
    let splittedText = title?.split('Idea Details page');
    return (
      <Box>
        <Typography>
          {splittedText[0]}{' '}
          <Button
            onClick={() => {
              navigate(`/businessIdeas/${ideaId}`);
            }}
            variant="textLink"
          >
            Idea Details page.
          </Button>
        </Typography>
      </Box>
    );
  };
  return (
    <>
      <Box
        className={
          tabItem?.question === 'Market'
            ? 'displayFlex alignItemsCenter justifySpaceBetween'
            : 'displayFlex alignItemsCenter'
        }
      >
        <InputLabel
          sx={{ overflow: 'visible' }}
          shrink={false}
          className="dashboardLabel1"
        >
          <Typography variant="customStyle1">
            {tabItem?.question}
            {tabItem?.toolTip && (
              <Tooltip title={tabItem?.toolTip}>
                <Info fontSize="small" className="ml1" />
              </Tooltip>
            )}
            {tabItem?.isSynced && (
              <Button
                className="ml05"
                variant="textButton4"
                onClick={() => {
                  navigate(`/businessIdeas/${ideaId}`);
                }}
              >
                {labels.businessPlan.syncedWithIdea}
              </Button>
            )}
          </Typography>
        </InputLabel>
        {tabItem?.question === 'Market' && (
          <Button
            onClick={() => {
              navigate(`/research/`);
            }}
            variant="textButton2"
          >
            {labels.businessIdeas.businessIdeasContainer.edtReasearchButton}
          </Button>
        )}
        {tabItem?.aiAutofill &&
          (tabItem?.isDependent &&
          !tabItem?.allowAIAutofill &&
          tabItem?.interDependentQuestion === null ? (
            <CustomWidthTooltip
              title={<CustomToolTipContent title={tabItem?.dependentText} />}
            >
              <IconButton
                onClick={() => {
                  handleAiCall(
                    tabItem,
                    tabItem?.id,
                    tabItem?.question,
                    tabItem?.answer
                  );
                }}
                className="ml1 iconButtonType4"
                disabled={
                  questionLoader.forQuestion === tabItem?.id ||
                  isDisabled === true
                }
              >
                {questionLoader.loading &&
                questionLoader.forQuestion === tabItem?.id ? (
                  <CircularProgress
                    className="mr05"
                    sx={{ color: 'rgb(255, 255, 255)' }}
                    size={'1rem'}
                  />
                ) : (
                  <CreateIcon fontSize="small" />
                )}
                {labels.ideaDetails.aiAutoFill}
              </IconButton>
            </CustomWidthTooltip>
          ) : (
            <Tooltip
              title={
                tabItem?.isDependencyFullfilled === false
                  ? tabItem?.dependentText
                  : tabItem?.answer?.trim()
                  ? 'Delete existing Text to use Ai Auto Fill'
                  : 'AI Autofill'
              }
            >
              <IconButton
                onClick={() => {
                  handleAiCall(
                    tabItem,
                    tabItem?.id,
                    tabItem?.question,
                    tabItem?.answer
                  );
                }}
                className="ml1 iconButtonType4"
                disabled={
                  questionLoader.forQuestion === tabItem?.id ||
                  isDisabled === true
                }
              >
                {questionLoader.loading &&
                questionLoader.forQuestion === tabItem?.id ? (
                  <CircularProgress
                    className="mr05"
                    sx={{ color: 'rgb(255, 255, 255)' }}
                    size={'1rem'}
                  />
                ) : (
                  <CreateIcon fontSize="small" />
                )}
                {labels.ideaDetails.aiAutoFill}
              </IconButton>
            </Tooltip>
          ))}
        {tabItem?.autoFillPro && (
          <Typography className="ml05" variant="customStyle2">
            {labels.ideaDetails.pro}
          </Typography>
        )}
      </Box>
      <TextField
        fullWidth
        placeholder={tabItem?.placeholder}
        className="textFieldType4"
        multiline={tabItem.type === 'textFieldMulti' ? true : false}
        minRows={3}
        value={tabItem.question === 'Market' ? 'None set yet' : inputValue}
        onChange={(e) => {
          handleInputChange(e, tabItem);
        }}
        onBlur={(e) => {
          if (!isTyping) {
            handleBlur(tabItem?.id, e.target.value);
          }
        }}
        draggable={false}
        disabled={tabItem.question === 'Market' || isDisabled === true}
        onDragStart={(e) => e.preventDefault()}
        onDragEnter={(e) => e.preventDefault()}
        onDragOver={(e) => e.preventDefault()}
        onDrop={(e) => e.preventDefault()}
        InputProps={{
          startAdornment: (tabItem?.question?.includes('CAC') ||
            tabItem?.question?.includes('ACV')) && (
            <InputAdornment position="start">₹</InputAdornment>
          ),
        }}
      />
    </>
  );
}
