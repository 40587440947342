import React, { useState } from 'react';
import { Button, Typography, Box } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import { uploadUserResumeService } from '../../../Services/ProfileServices';
import { labels } from '../../../data/constants';
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
const PdfUploader = ({ workspaceId }) => {
  const [file, setFile] = useState(null);

  const handleFileChange = (fileReceived) => {
    const selectedFile = fileReceived;

    // Check if the file is a PDF
    if (selectedFile && selectedFile.type === 'application/pdf') {
      setFile(selectedFile);
      handleSubmit(selectedFile);
    } else {
      setFile(null);
    }
  };

  const handleSubmit = async (file) => {
    try {
      if (file) {
        // Handle your file upload logic here
        let payload = new FormData();
        payload.append('workspaceid', workspaceId);
        payload.append('resume', file);
        const response = await uploadUserResumeService(payload);
        if (response) {
          setFile(null);
        } else {
          setFile(null);
        }
      }
    } catch (e) {
      console.log(e);
      setFile(null);
    }
  };

  return (
    <Box className="displayFlex alignItemsCenter">
      <Button
        component="label"
        role={undefined}
        variant="textButton3"
        tabIndex={-1}
        startIcon={<CloudUploadIcon />}
        className="ml05"
      >
        {labels.profile.uploadResume}
        <VisuallyHiddenInput
          type="file"
          onChange={(e) => {
            handleFileChange(e.target.files[0]);
            e.target.value = null;
          }}
          multiple
        />
      </Button>
    </Box>
  );
};

export default PdfUploader;
